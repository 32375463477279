import React, { useState } from 'react';
import './App.css';

function InfoIcon() {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <div className="info-icon-container">
      <div 
        className="info-icon"
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        ℹ
        {showTooltip && (
          <div className="tooltip">
            Start your day with this app ☀️ <br></br> <br></br>Pin it to your brower! 📌 <br /> <br></br>
            Want your own session with task history? <br></br> <a href="/signup"><br></br>Create a free account</a>
          </div>
        )}
      </div>
    </div>
  );
}

export default InfoIcon;