// App.js
import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import logo from './assets/whatdoIdonow.png';
import pipeIcon from './assets/pipe-icon.png';
import pipeSoundFile from './sounds/pipe-travel.wav';
import AddTask from './components/AddTask';
import Matrice from './components/Matrice';
import InfoIcon from './InfoIcon';

function App() {
  const [tasks, setTasks] = useState([]);
  const [showMatrix, setShowMatrix] = useState(false);
  const [showLogo, setShowLogo] = useState(true);
  const pipeSound = useRef(new Audio(pipeSoundFile));

  // Load tasks from local storage on initial render
  useEffect(() => {
    const savedTasks = localStorage.getItem('tasks');
    if (savedTasks) setTasks(JSON.parse(savedTasks));
  }, []);

  // Save tasks to local storage whenever they change
  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  // Add a new task to the task list
  const addTask = (taskData) => {
    setTasks([...tasks, taskData]);
  };

  // Delete a task by its ID
  const deleteTask = (id) => {
    setTasks(tasks.filter((task) => task.id !== id));
  };

  // Move a task to a specific quadrant and update its importance/deadline
  const moveTask = (taskId, targetQuadrant) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) => {
        if (task.id === taskId) {
          switch (targetQuadrant) {
            case 'important-urgent':
              return { ...task, importance: 3, deadline: new Date() }; // Important and urgent
            case 'important-non-urgent':
              return { ...task, importance: 3, deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) }; // Important but not urgent
            case 'non-important-urgent':
              return { ...task, importance: 2, deadline: new Date() }; // Not important but urgent
            case 'non-important-non-urgent':
              return { ...task, importance: 2, deadline: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000) }; // Neither urgent nor important
            default:
              return task;
          }
        }
        return task;
      })
    );
  };

  // Play sound effect when entering or exiting the matrix
  const playPipeSound = () => {
    pipeSound.current.pause();
    pipeSound.current.currentTime = 0;
    pipeSound.current.play();
  };

  // Show matrix and play sound
  const enterMatrix = () => {
    playPipeSound();
    setShowMatrix(true);
    setShowLogo(false);
  };

  // Go back to add task view
  const exitMatrix = () => {
    playPipeSound();
    setShowMatrix(false);
    setShowLogo(true);
  };

  return (
    <div className="container">
      <InfoIcon />
      {showLogo && <img src={logo} alt="Logo WhatDoIDoNow" className="logo" />}

      {!showMatrix ? (
        <AddTask onAddTask={addTask} onEnterMatrix={enterMatrix} pipeIcon={pipeIcon} />
      ) : (
        <Matrice
          tasks={tasks}
          onDeleteTask={deleteTask}
          onGoBack={exitMatrix}
          pipeIcon={pipeIcon}
          moveTask={moveTask}
        />
      )}
    </div>
  );
}

export default App;