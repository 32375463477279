import React, { useState, useRef } from 'react';
import { useDrag, useDrop, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Import sound files
import hoverSoundFile from '../sounds/quadrant-hovering.wav';
import dragSound from '../sounds/drag.wav';
import dropSound from '../sounds/ndrop.wav';
import taskSuccessSound from '../sounds/task-success.wav';
import taskAchieveSound from '../sounds/task-achieve.wav';

const ItemTypes = {
  TASK: 'task',
};

const playHoverSound = () => {
  const hoverSound = new Audio(hoverSoundFile);
  hoverSound.play();
};

function Task({ task, onDeleteTask }) {
  const [isCompleted, setIsCompleted] = useState(false);

  // Set up refs for sounds
  const dragAudio = useRef(new Audio(dragSound));
  const dropAudio = useRef(new Audio(dropSound));
  const taskSuccessAudio = useRef(new Audio(taskSuccessSound));
  const taskAchieveAudio = useRef(new Audio(taskAchieveSound));

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TASK,
    item: () => {
      dragAudio.current.play();
      return { id: task.id };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      dropAudio.current.play();
    },
});

  const handleComplete = () => {
    setIsCompleted(true);
    taskSuccessAudio.current.play();
  };

  const handleDelete = () => {
    taskAchieveAudio.current.play();
    onDeleteTask(task.id);
  };

  return (
    <div
      ref={drag}
      className="task-item"
      style={{
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <div
        className="task-text"
        style={{
          flex: 1,
          textDecoration: isCompleted ? 'line-through' : 'none',
          color: isCompleted ? '#888' : '#333',
          margin: 0,
        }}
      >
        {task.task} - {task.deadline ? new Date(task.deadline).toLocaleString() : 'No deadline set'}
      </div>
      {isCompleted ? (
        <button
          onClick={handleDelete}
          className="delete-button"
          style={{
            fontSize: '1.8rem',
            background: 'none',
            border: 'none',
            color: '#ff5252',
            cursor: 'pointer',
            marginLeft: '10px',
          }}
        >
          🗑️
        </button>
      ) : (
        <button
          onClick={handleComplete}
          className="complete-button"
          style={{
            fontSize: '1.8rem',
            background: 'none',
            border: 'none',
            color: '#4caf50',
            cursor: 'pointer',
            marginLeft: '10px',
          }}
        >
          ✅
        </button>
      )}
    </div>
  );
}

function Quadrant({ title, tasks, quadrantKey, onDeleteTask, moveTask }) {
  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    drop: (item) => moveTask(item.id, quadrantKey),
  });

  return (
    <div
      ref={drop}
      className={`quadrant quadrant-${quadrantKey}`}
      onMouseEnter={playHoverSound}
    >
      <h3>{title}</h3>
      {tasks.map((task) => (
        <Task key={task.id} task={task} onDeleteTask={onDeleteTask} />
      ))}
    </div>
  );
}

function Matrice({ tasks, onDeleteTask, onGoBack, pipeIcon, moveTask }) {
  const isUrgent = (deadline) => {
    const now = new Date();
    return deadline && new Date(deadline) - now <= 3 * 24 * 60 * 60 * 1000;
  };

  const isImportant = (importance) => importance >= 3;

  const getQuadrantTasks = (importanceFilter, urgencyFilter) => {
    return tasks.filter(
      (task) => importanceFilter(isImportant(task.importance)) && urgencyFilter(isUrgent(task.deadline))
    );
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="matrix">
        <div className="matrix-quadrants">
          <Quadrant
            title="Important & Urgent 🔥"
            tasks={getQuadrantTasks((important) => important, (urgent) => urgent)}
            quadrantKey="important-urgent"
            onDeleteTask={onDeleteTask}
            moveTask={moveTask}
          />
          <Quadrant
            title="Important & Not Urgent ✅"
            tasks={getQuadrantTasks((important) => important, (urgent) => !urgent)}
            quadrantKey="important-non-urgent"
            onDeleteTask={onDeleteTask}
            moveTask={moveTask}
          />
          <Quadrant
            title="Not Important & Urgent 😤"
            tasks={getQuadrantTasks((important) => !important, (urgent) => urgent)}
            quadrantKey="non-important-urgent"
            onDeleteTask={onDeleteTask}
            moveTask={moveTask}
          />
          <Quadrant
            title="Not Important & Not Urgent 🚮"
            tasks={getQuadrantTasks((important) => !important, (urgent) => !urgent)}
            quadrantKey="non-important-non-urgent"
            onDeleteTask={onDeleteTask}
            moveTask={moveTask}
          />
        </div>

        <div className="up-arrow" onClick={onGoBack} style={{ textAlign: 'center', cursor: 'pointer', marginTop: '2rem' }}>
          <img src={pipeIcon} alt="Pipe Icon" className="pipe-icon up" />
        </div>
      </div>
    </DndProvider>
  );
}

export default Matrice;