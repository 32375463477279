import React, { useState } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import Rating from 'react-rating-stars-component';
import emojiFallingSoundFile from '../sounds/emoji-falling.wav';


function AddTask({ onAddTask, onEnterMatrix, pipeIcon }) {
  const [task, setTask] = useState('');
  const [importance, setImportance] = useState(0);
  const [deadline, setDeadline] = useState(new Date());
  const [buttonFeedback, setButtonFeedback] = useState(false);
  const [showEmoji, setShowEmoji] = useState(false);
  const emojiFallingSound = new Audio(emojiFallingSoundFile);


  const handleAddTask = () => {
    if (!task.trim()) {
      alert("Task cannot be empty");
      return;
    }

    const taskData = {
      id: Date.now(),
      task,
      importance,
      deadline
    };

    onAddTask(taskData);
    setButtonFeedback(true);
    setShowEmoji(true);

    setTask('');
    setImportance(0);
    setDeadline(new Date());
  };

  return (
    <div className="task-form">
      <label>Ok so... </label>
      <input
        type="text"
        value={task}
        onChange={(e) => setTask(e.target.value)}
        placeholder="Enter task..."
      />

      <label>Important?</label>
      <Rating
        count={5}
        onChange={(newValue) => setImportance(newValue)}
        size={40}
        activeColor="#ffd700"
      />

      <label>For when?</label>
      <Flatpickr
        data-enable-time
        value={deadline}
        onChange={(date) => setDeadline(date[0])}
        options={{
          enableTime: true,
          dateFormat: "d/m/Y H:i",
        }}
        className="custom-flatpickr"
      />

<button
  onClick={() => {
    handleAddTask();
    emojiFallingSound.play(); // Play sound when button is clicked
  }}
  className={buttonFeedback ? 'button-feedback' : ''}
  style={{ backgroundColor: buttonFeedback ? '#FFA500' : '#4CAF50' }}
>
  {buttonFeedback ? 'Task successfully added!' : 'Add task to the matrix!'}
</button>

{/* Floating and Falling Emoji */}
{showEmoji && (
  <div
    style={{
      position: 'absolute',
      top: '60rem', // Adjust to control start position
      left: '50%',
      transform: 'translateX(-50%)',
      fontSize: '2rem',
      animation: 'floatAndFall 2s ease-in forwards' // Animation keyframe
    }}
  >
    📝
  </div>
)}



      <div className="down-arrow" onClick={onEnterMatrix} style={{ textAlign: 'center', cursor: 'pointer', marginTop: '1rem' }}>
        <img src={pipeIcon} alt="Pipe Icon" className="pipe-icon down" />
      </div>

      {/* Animation Keyframes */}
      <style>
        {`
          @keyframes floatAndFall {
            0% {
              transform: translateX(-50%) translateY(0) scale(1);
            }
            50% {
              transform: translateX(-50%) translateY(-20px) scale(1); /* Floating effect */
            }
            100% {
              transform: translateX(-50%) translateY(150px) scale(0.5); /* Falling effect */
            }
          }
        `}
      </style>
    </div>
  );
}

export default AddTask;